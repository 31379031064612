import React, { useEffect, useState } from "react";
import { Link } from "gatsby";
import CityFinder from "../helper/CityFinder";
import StringBuilder from "../helper/StringBuilder";

const Footer = ({ small, productSlug, productName, product }) => {
    // fetch(process.env.GATSBY_API_URL + "/userTrack", {

    let userTrack = { lastVisitTrack: nowTime };
    const nowTime = Date.now();

    if (typeof window != "undefined") {
        userTrack = JSON.parse(localStorage.getItem("userTrack")) ?? {
            lastVisitTrack: nowTime,
        };
    }
    const is24HoursElapsed =
        nowTime == userTrack.lastVisitTrack
            ? true
            : nowTime - userTrack.lastVisitTrack > 86400000;
    const stateData = is24HoursElapsed
        ? false
        : Boolean(userTrack.usertype % 2 != 0);
    const [showChat, setShowChat] = useState(stateData);
    // function checkShow() {}
    useEffect(() => {
        // Chat is displayed after scrolling for 300 pixels
        if (typeof window !== "undefined") {
            // const nowTime = Date.now();

            // const userTrack = JSON.parse(localStorage.getItem("userTrack")) ?? {
            //     lastVisitTrack: nowTime,
            // };
            // if (userTrack.usertype) {
            //     setShowChat(Boolean(userTrack.usertype % 2 !== 0));
            // }
            // console.log("showchat is", showChat);
            // const is24HoursElapsed =
            //     nowTime == userTrack.lastVisitTrack
            //         ? true
            //         : nowTime - userTrack.lastVisitTrack > 86400000;
            // console.log("is24HoursElapsed", is24HoursElapsed);
            // console.log("userTrack", userTrack);
            // console.log(
            //     "nowTime - userTrack.lastVisitTrack",
            //     nowTime - Number(userTrack.lastVisitTrack)
            // );

            setTimeout(() => {
                if (document.getElementById("xenioo-chat-widget")) {
                    document.getElementById(
                        "xenioo-chat-widget"
                    ).style.display = "none";
                }
            }, 1000);
            // if (userTrack && is24HoursElapsed) {
            //     fetch(process.env.GATSBY_API_URL + "/userTrack").then(
            //         (response) =>
            //             response.json().then((data) => {
            //                 // console.log("data is", data);
            //                 localStorage.setItem(
            //                     "userTrack",
            //                     JSON.stringify({
            //                         lastVisitTrack: Date.now(),
            //                         usertype: data.usertype,
            //                     })
            //                 );
            //                 setShowChat(Boolean(data.usertype % 2 != 0));
            //                 // checkShow();
            //                 console.log(
            //                     "Boolean(data.usertype % 2 != 0) is",
            //                     Boolean(data.usertype % 2 != 0)
            //                 );
            //                 setTimeout(() => {
            //                     if (
            //                         document.getElementById(
            //                             "xenioo-chat-widget"
            //                         )
            //                     ) {
            //                         Boolean(data.usertype % 2 != 0)
            //                             ? (document.getElementById(
            //                                   "xenioo-chat-widget"
            //                               ).style.display = "block")
            //                             : (document.getElementById(
            //                                   "xenioo-chat-widget"
            //                               ).style.display = "none");
            //                     }
            //                 }, 1000);
            //                 // console.log("showchat internal", this.state);

            //                 // Chat is displayed after scrolling for 300 pixels
            //             })
            //     );
            // }
            // if (!showChat) {
            const handleScrollChatVisiblity = () => {
                // window.pageYOffset > 800 ? setShowChat(true) : setShowChat(false);
                // const userTrack = JSON.parse(
                //     localStorage.getItem("userTrack")
                // ) ?? {
                //     lastVisitTrack: nowTime,
                // };
                // if (
                //     // userTrack.usertype &&
                //     // Boolean(userTrack.usertype % 2 !== 0) &&
                //     document.getElementById("xenioo-chat-widget")
                // ) {
                //     document.getElementById(
                //         "xenioo-chat-widget"
                //     ).style.display = "block";
                //     return;
                // }

                // console.log(
                //     "handleScrollChatVisiblity fired with ",
                //     window.pageYOffset
                // );
                if (document.getElementById("xenioo-chat-widget")) {
                    if (window.pageYOffset > 730) {
                        document.getElementById(
                            "xenioo-chat-widget"
                        ).style.display = "block";
                    } else {
                        document.getElementById(
                            "xenioo-chat-widget"
                        ).style.display = "none";
                    }
                }
            };
            window.addEventListener("scroll", handleScrollChatVisiblity);
            return () => {};
            window.removeEventListener("scroll", handleScrollChatVisiblity);
            // }
        }
    }, [showChat]);
    // useEffect(() => {
    //     if (typeof window !== "undefined") {
    //         setTimeout(() => {
    //             const nowTime = Date.now();

    //             const userTrack = JSON.parse(
    //                 localStorage.getItem("userTrack")
    //             ) ?? {
    //                 lastVisitTrack: nowTime,
    //             };
    //             if (userTrack.usertype) {
    //                 setShowChat(Boolean(userTrack.usertype % 2 !== 0));
    //             }
    //             const is24HoursElapsed =
    //                 nowTime == userTrack.lastVisitTrack
    //                     ? true
    //                     : nowTime - userTrack.lastVisitTrack > 86400000;
    //             console.log("is24HoursElapsed", is24HoursElapsed);
    //             console.log("userTrack", userTrack);
    //             console.log(
    //                 "nowTime - userTrack.lastVisitTrack",
    //                 nowTime - Number(userTrack.lastVisitTrack)
    //             );

    //             if (typeof window !== "undefined") {
    //                 console.log("shoWcah", showChat);
    //                 // setTimeout(() => {
    //                 // if (
    //                 //     showChat &&
    //                 //     document.getElementById("xenioo-chat-widget")
    //                 // ) {
    //                 //     document.getElementById(
    //                 //         "xenioo-chat-widget"
    //                 //     ).style.display = "block";
    //                 // }
    //                 // if (
    //                 //     !showChat &&
    //                 //     document.getElementById("xenioo-chat-widget")
    //                 // ) {
    //                 //     document.getElementById(
    //                 //         "xenioo-chat-widget"
    //                 //     ).style.display = "none";
    //                 // }
    //                 // }, 1000);
    //             }

    //             if (userTrack && is24HoursElapsed) {
    //                 fetch(process.env.GATSBY_API_URL + "/userTrack").then(
    //                     (response) =>
    //                         response.json().then((data) => {
    //                             // console.log("data is", data);
    //                             localStorage.setItem(
    //                                 "userTrack",
    //                                 JSON.stringify({
    //                                     lastVisitTrack: Date.now(),
    //                                     usertype: data.usertype,
    //                                 })
    //                             );
    //                             this.setState({
    //                                 showChat: Boolean(data.usertype % 2 !== 0), //10%2 = 0 // 0!=0 false for even numbers show for odd numbers
    //                             });

    //                             // console.log("showchat internal", this.state);

    //                             // Chat is displayed after scrolling for 300 pixels
    //                         })
    //                 );
    //             }
    //         }, 100);
    //     }
    // }, []);
    if (typeof small === "undefined") {
        small = false;
    }
    let cityFinder = new CityFinder();
    let city = cityFinder.getCity();
    let stringBuilder = new StringBuilder(city, product);
    productName = stringBuilder.getFooterProductName();
    // BLISTER temporary structue for SEO
    productSlug = productSlug ? "/" + productSlug : "";
    let slugString = "";
    if (typeof city.slug !== "undefined") {
        slugString = "/" + city.slug;
    }
    return (
        <footer className="footer">
            {small === false && (
                <div className="footer-container">
                    <div className="footer-part-1 has-text-centered">
                        <div className="columns">
                            <div className="column is-one-quarter">
                                <div className="header">MeinAlarm24</div>
                                <div className="content">
                                    {/* <p>
                                        <Link
                                            className="footer-item"
                                            to="/kooperationspartner"
                                        >
                                            kooperationspartner
                                        </Link>
                                    </p> */}
                                    <p>
                                        <Link
                                            className="footer-item"
                                            to={slugString + "/alarmanlagen/"}
                                        >
                                            Alarmanlagen
                                        </Link>
                                    </p>
                                    <p>
                                        <Link
                                            className="footer-item"
                                            to="/alarmanlagen-haus"
                                        >
                                            Alarmanlagen für Häuser
                                        </Link>
                                    </p>
                                    <p>
                                        <Link
                                            className="footer-item"
                                            to="/alarmanlagen-firma"
                                        >
                                            Alarmanlagen für Firmen
                                        </Link>
                                    </p>

                                    <p>
                                        <Link
                                            className="footer-item"
                                            to={
                                                slugString +
                                                "/videoueberwachung/"
                                            }
                                        >
                                            Videoüberwachung
                                        </Link>
                                    </p>
                                    <p>
                                        <Link
                                            className="footer-item"
                                            to={
                                                slugString + "/schliessanlagen/"
                                            }
                                        >
                                            Schließanlagen
                                        </Link>
                                    </p>
                                    <p>
                                        <Link
                                            className="footer-item"
                                            to={slugString + "/tuerschloesser/"}
                                        >
                                            Elektronische Türschlösser
                                        </Link>
                                    </p>
                                    <p>
                                        <Link
                                            className="footer-item"
                                            to={
                                                slugString +
                                                "/brandmeldeanlagen/"
                                            }
                                        >
                                            Brandmeldeanlagen
                                        </Link>
                                    </p>
                                    <p>
                                        <Link
                                            className="footer-item"
                                            to={slugString + "/fieber-kameras/"}
                                        >
                                            Fieber-Kameras
                                        </Link>
                                    </p>
                                    <p>
                                        <Link
                                            className="footer-item"
                                            to={
                                                slugString +
                                                "/einlasskontrolle/"
                                            }
                                        >
                                            Einlasskontrollsysteme
                                        </Link>
                                    </p>
                                    <p>
                                        <Link
                                            className="footer-item"
                                            to="/preisrechner/"
                                        >
                                            Preisrechner
                                        </Link>
                                    </p>
                                    <p>
                                        <Link
                                            className="footer-item"
                                            to="/beratungstermine/"
                                        >
                                            Beratungstermin buchen
                                        </Link>
                                    </p>
                                    <p>
                                        <Link
                                            className="footer-item"
                                            to="/kunden-werben-kunden/"
                                        >
                                            Kunden-werben-Kunden
                                        </Link>
                                    </p>
                                    <p>
                                        <Link
                                            className="footer-item"
                                            to="/preisrechner/"
                                        >
                                            Wir sichern Ihr Zuhause
                                        </Link>
                                    </p>
                                    <p>
                                        <Link
                                            className="footer-item"
                                            to="/preisrechner/"
                                        >
                                            Wir sichern Ihr Gewerbe
                                        </Link>
                                    </p>
                                    <p>
                                        <Link
                                            className="footer-item"
                                            to="/ratgeber/"
                                        >
                                            Ratgeber
                                        </Link>
                                    </p>
                                    <p>
                                        <Link
                                            className="footer-item"
                                            to="/erklaervideo/"
                                        >
                                            Erklärvideo
                                        </Link>
                                    </p>
                                    <p>
                                        <Link
                                            className="footer-item"
                                            to="/einbruchsatlas/"
                                        >
                                            Einbruchsatlas
                                        </Link>
                                    </p>
                                    <p>
                                        <Link
                                            className="footer-item"
                                            to="/karriere/"
                                        >
                                            Karriere
                                        </Link>
                                    </p>
                                    <p>
                                        <Link
                                            className="footer-item"
                                            to="/ueber-uns/"
                                        >
                                            Über uns
                                        </Link>
                                    </p>
                                </div>
                            </div>
                            <div className="column is-one-quarter">
                                <div className="header">Unsere Standorte</div>
                                <div className="content">
                                    <p>
                                        <Link
                                            className="footer-item"
                                            to={"/hamburg" + productSlug + "/"}
                                        >
                                            {productName} in Hamburg
                                        </Link>
                                    </p>
                                    <p>
                                        <Link
                                            className="footer-item"
                                            to={"/berlin" + productSlug + "/"}
                                        >
                                            {productName} in Berlin
                                        </Link>
                                    </p>
                                    <p>
                                        <Link
                                            className="footer-item"
                                            to={"/muenchen" + productSlug + "/"}
                                        >
                                            {productName} in München
                                        </Link>
                                    </p>
                                    <p>
                                        <Link
                                            className="footer-item"
                                            to={"/koeln" + productSlug + "/"}
                                        >
                                            {productName} in Köln
                                        </Link>
                                    </p>
                                    <p>
                                        <Link
                                            className="footer-item"
                                            to={
                                                "/duesseldorf" +
                                                productSlug +
                                                "/"
                                            }
                                        >
                                            {productName} in Düsseldorf
                                        </Link>
                                    </p>
                                    <p>
                                        <Link
                                            className="footer-item"
                                            to={"/bremen" + productSlug + "/"}
                                        >
                                            {productName} in Bremen
                                        </Link>
                                    </p>
                                    <p>
                                        <Link
                                            className="footer-item"
                                            to={
                                                "/oldenburg" + productSlug + "/"
                                            }
                                        >
                                            {productName} in Oldenburg
                                        </Link>
                                    </p>
                                    <p>
                                        <Link
                                            className="footer-item"
                                            to={"/essen" + productSlug + "/"}
                                        >
                                            {productName} in Essen
                                        </Link>
                                    </p>
                                    <p>
                                        <Link
                                            className="footer-item"
                                            to={"/dortmund" + productSlug + "/"}
                                        >
                                            {productName} in Dortmund
                                        </Link>
                                    </p>
                                    <p>
                                        <Link
                                            className="footer-item"
                                            to={"/bochum" + productSlug + "/"}
                                        >
                                            {productName} in Bochum
                                        </Link>
                                    </p>
                                    <p>
                                        <Link
                                            className="footer-item"
                                            to={"/duisburg" + productSlug + "/"}
                                        >
                                            {productName} in Duisburg
                                        </Link>
                                    </p>
                                    <p>
                                        <Link
                                            className="footer-item"
                                            to={"/krefeld" + productSlug + "/"}
                                        >
                                            {productName} in Krefeld
                                        </Link>
                                    </p>
                                    <p>
                                        <Link
                                            className="footer-item"
                                            to={
                                                "/moenchengladbach" +
                                                productSlug +
                                                "/"
                                            }
                                        >
                                            {productName} in Mönchengladbach
                                        </Link>
                                    </p>
                                    <p>
                                        <Link
                                            className="footer-item"
                                            to={"/luebeck" + productSlug + "/"}
                                        >
                                            {productName} in Lübeck
                                        </Link>
                                    </p>
                                    <p>
                                        <Link
                                            className="footer-item"
                                            to={"/kiel" + productSlug + "/"}
                                        >
                                            {productName} in Kiel
                                        </Link>
                                    </p>
                                    <p>
                                        <Link
                                            className="footer-item"
                                            to={"/potsdam" + productSlug + "/"}
                                        >
                                            {productName} in Potsdam
                                        </Link>
                                    </p>
                                    <p>
                                        <Link
                                            className="footer-item"
                                            to={"/bonn" + productSlug + "/"}
                                        >
                                            {productName} in Bonn
                                        </Link>
                                    </p>
                                    <p>
                                        <Link
                                            className="footer-item"
                                            to={
                                                "/niedersachsen" +
                                                productSlug +
                                                "/"
                                            }
                                        >
                                            {productName} in Niedersachsen
                                        </Link>
                                    </p>
                                    <p>
                                        <Link
                                            className="footer-item"
                                            to={"/bayern" + productSlug + "/"}
                                        >
                                            {productName} in Bayern
                                        </Link>
                                    </p>
                                    <p>
                                        <Link
                                            className="footer-item"
                                            to={"/hannover" + productSlug + "/"}
                                        >
                                            {productName} in Hannover
                                        </Link>
                                    </p>
                                    <p>
                                        <Link
                                            className="footer-item"
                                            to={
                                                "/frankfurt" + productSlug + "/"
                                            }
                                        >
                                            {productName} in Frankfurt am Main
                                        </Link>
                                    </p>
                                    <p>
                                        <Link
                                            className="footer-item"
                                            to={
                                                "/stuttgart" + productSlug + "/"
                                            }
                                        >
                                            {productName} in Stuttgart
                                        </Link>
                                    </p>
                                    <p>
                                        <Link
                                            className="footer-item"
                                            to={
                                                "/magdeburg" + productSlug + "/"
                                            }
                                        >
                                            {productName} in Magdeburg
                                        </Link>
                                    </p>
                                    <p>
                                        <Link
                                            className="footer-item"
                                            to={
                                                "/schleswig-holstein" +
                                                productSlug +
                                                "/"
                                            }
                                        >
                                            {productName} in Schleswig-Holstein
                                        </Link>
                                    </p>
                                    <p>
                                        <Link
                                            className="footer-item"
                                            to={
                                                "/ruhrgebiet" +
                                                productSlug +
                                                "/"
                                            }
                                        >
                                            {productName} im Ruhrgebiet
                                        </Link>
                                    </p>
                                    <p>
                                        <Link
                                            className="footer-item"
                                            to={
                                                "/rheinland" + productSlug + "/"
                                            }
                                        >
                                            {productName} im Rheinland
                                        </Link>
                                    </p>
                                    <p>
                                        <Link
                                            className="footer-item"
                                            to={
                                                "/rhein-main" +
                                                productSlug +
                                                "/"
                                            }
                                        >
                                            {productName} im Rhein-Main
                                        </Link>
                                    </p>
                                    <p>
                                        <Link
                                            className="footer-item"
                                            to={
                                                "/Nordrhein-Westfalen" +
                                                productSlug +
                                                "/"
                                            }
                                        >
                                            {productName} in Nordrhein-Westfalen
                                        </Link>
                                    </p>
                                </div>
                            </div>
                            <div className="column is-three-quarter">
                                <div className="header">
                                    Beliebt im Ratgeber
                                </div>
                                <div className="content">
                                    <p>
                                        <Link
                                            className="footer-item"
                                            to="/alarmanlagen/funk-alarmanlagen-vorteile/"
                                        >
                                            Funk Alarmanlagen: Übersicht der
                                            Modelle und Vorteile
                                        </Link>
                                    </p>
                                    <p>
                                        <Link
                                            className="footer-item"
                                            to="/alarmanlagen/alarmanlagen-test-2018/"
                                        >
                                            Wer ist der Alarmanlagen Testsieger{" "}
                                            {new Date().getFullYear()}?
                                        </Link>
                                    </p>
                                    <p>
                                        <Link
                                            className="footer-item"
                                            to="/alarmanlagen/alarmanlage-haus-ratgeber/"
                                        >
                                            Alarmanlage Haus Ratgeber{" "}
                                            {new Date().getFullYear()}
                                        </Link>
                                    </p>
                                    <p>
                                        <Link
                                            className="footer-item"
                                            to="/alarmanlagen/alarmanlage-kaufen-2020/"
                                        >
                                            Alarmanlage kaufen{" "}
                                            {new Date().getFullYear()}
                                        </Link>
                                    </p>
                                    <p>
                                        <Link
                                            className="footer-item"
                                            to="/video/thermalkamera-ratgeber/"
                                        >
                                            Thermalkameras: Unser Ratgeber für{" "}
                                            {new Date().getFullYear()}
                                        </Link>
                                    </p>
                                </div>
                            </div>
                            <div className="column  is-one-quarter">
                                <div className="header">Auszeichnungen</div>
                                <div className="content">
                                    <div id="auorg-bg">
                                        <a
                                            href="https://www.ausgezeichnet.org"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            title="Unabh&auml;ngige Bewertungen, Kundenbewertungen und G&uuml;tesiegel von Ausgezeichnet.org"
                                            className="auorg-br"
                                        >
                                            <span
                                                style={{
                                                    fontSize: "8px",
                                                    fontWeight: "normal",
                                                    textTransform: "uppercase",
                                                }}
                                            >
                                                Ausgezeichnet.org
                                            </span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="columns social-media">
                            <div className="column">
                                <div className="columns">
                                    <div className="column is-narrow text">
                                        <div className="header">
                                            Folgen Sie uns auf
                                        </div>
                                    </div>
                                    <div className="column">
                                        <div className="columns is-mobile">
                                            <div className="column is-narrow facebook">
                                                <a
                                                    className="fab fa-facebook-square"
                                                    href="https://www.facebook.com/MeinAlarm24/"
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    &nbsp;
                                                </a>
                                            </div>
                                            <div className="column is-narrow linkedin">
                                                <a
                                                    className="fab fa-linkedin"
                                                    href="https://www.linkedin.com/company/24776008/"
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    &nbsp;
                                                </a>
                                            </div>
                                            <div className="column is-narrow xing">
                                                <a
                                                    className="fab fa-xing-square"
                                                    href="https://www.xing.com/companies/meinalarm24gmbh"
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    &nbsp;
                                                </a>
                                            </div>
                                            <div className="column is-narrow xing">
                                                <a
                                                    className="fab fa-instagram-square"
                                                    href="https://www.instagram.com/meinalarm24/"
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    &nbsp;
                                                </a>
                                            </div>
                                            <div className="column is-narrow xing">
                                                <a
                                                    className="fab fa-youtube-square"
                                                    href="https://www.youtube.com/channel/UCJGczjzlw7cb0nFAXBFttyg"
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    &nbsp;
                                                </a>
                                            </div>
                                            <div className="column"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="footer-part-2">
                        <div className="columns">
                            <div className="column is-one-half left">
                                <Link className="footer-item" to="/agb/">
                                    AGB
                                </Link>{" "}
                                |&nbsp;
                                <Link
                                    className="footer-item"
                                    to="/datenschutz/"
                                >
                                    Datenschutz
                                </Link>{" "}
                                |&nbsp;
                                <Link className="footer-item" to="/impressum/">
                                    Impressum
                                </Link>
                            </div>
                            <div className="column is-one-half right">
                                Copyright &copy; {new Date().getFullYear()}{" "}
                                MeinAlarm24 GmbH
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {small && (
                <div className="footer-container-small">
                    <div className="columns">
                        <div className="column is-half is-offset-one-quarter has-text-centered">
                            <Link className="footer-item" to="/agb/">
                                AGB
                            </Link>{" "}
                            |
                            <Link className="footer-item" to="/datenschutz/">
                                Datenschutz
                            </Link>{" "}
                            |
                            <Link className="footer-item" to="/impressum/">
                                Impressum
                            </Link>{" "}
                            <br className="is-hidden-desktop" />
                            Copyright &copy; {new Date().getFullYear()}{" "}
                            MeinAlarm24 GmbH
                        </div>
                    </div>
                </div>
            )}
        </footer>
    );
};
export default Footer;
