import {
    Alarmanlage,
    Videoueberwachung,
    Tuerschloss,
    Brand,
    Fieberkamera,
    Einlasskontrolle,
} from "../components/PriceCalculator/elements/Constants";

export default class StringBuilder {
    constructor(city, product) {
        this.city = city;
        this.product = product;
    }

    getAusStadtUndUmgebung() {
        if (typeof this.city === "undefined") {
            return "";
        }
        if (
            this.city.name === "Ruhrgebiet" ||
            this.city.name === "Rheinland" ||
            this.city.name === "Rhein-Main"
        ) {
            return " aus dem " + this.city.name;
        }

        return " aus" + this.getStadtUndUmgebung();
    }

    getAusStadt() {
        if (typeof this.city === "undefined") {
            return "";
        }

        return " aus " + this.getStadt();
    }

    getInStadt() {
        if (typeof this.city === "undefined") {
            return "";
        }
        if (
            this.city.name === "Ruhrgebiet" ||
            this.city.name === "Rheinland" ||
            this.city.name === "Rhein-Main"
        ) {
            return " im " + this.city.name;
        }

        return " in " + this.getStadt();
    }

    getInStadtUndUmgebung() {
        if (typeof this.city === "undefined") {
            return "";
        }
        if (
            this.city.name === "Ruhrgebiet" ||
            this.city.name === "Rheinland" ||
            this.city.name === "Rhein-Main"
        ) {
            return " im " + this.city.name;
        }

        return " in" + this.getStadtUndUmgebung();
    }

    getStadtUndUmgebung() {
        if (typeof this.city === "undefined") {
            return "";
        }
        if (
            this.city.name === "Ruhrgebiet" ||
            this.city.name === "Rheinland" ||
            this.city.name === "Rhein-Main"
        ) {
            return " das " + this.city.name + " & Umgebung";
        }

        return this.getStadt() + " & Umgebung";
    }

    getStadt() {
        if (typeof this.city === "undefined") {
            return "";
        }

        return " " + this.city.name;
    }

    getHeroProductName() {
        switch (this.product) {
            case Alarmanlage:
                return "Alarmanlagen";

            case Videoueberwachung:
                return "Videoüberwachung";

            case Tuerschloss:
                return "Elektronische Schließanlagen";

            default:
                return "Sicherheitstechnik";
        }
    }

    getFooterProductName() {
        switch (this.product) {
            case Alarmanlage:
                return "Alarmanlagen";

            case Videoueberwachung:
                return "Videoüberwachung";

            case Tuerschloss:
                return "Schließanlagen";

            default:
                return "Sicherheitstechnik";
        }
    }

    getHowToTitle() {
        switch (this.product) {
            case Alarmanlage:
                return "einer Alarmanlage";

            case Videoueberwachung:
                return "einer Videoüberwachungsanlage";

            case Tuerschloss:
                return "einer Schließanlage";

            default:
                return "mehr Sicherheit";
        }
    }

    getSingularProductName() {
        switch (this.product) {
            case Alarmanlage:
                return "Alarmanlage";

            case Videoueberwachung:
                return "Videoüberwachungsanlage";

            case Tuerschloss:
                return "Schließanlage";

            case Brand:
                return "Brandmeldeanlage";

            default:
                return "Anlage";
        }
    }

    getMetaDataProductName() {
        switch (this.product) {
            case Alarmanlage:
                return "Alarmanlagen";

            case Videoueberwachung:
                return "Videoanlagen";

            case Tuerschloss:
                return "Schließanlagen";

            case Brand:
                return "Brandmeldeanlagen";

            case Fieberkamera:
                return "Fieberkameras";

            case Einlasskontrolle:
                return "Einlasskontrollsysteme";

            default:
                return "Sicherheitstechnik";
        }
    }

    getMetaDataProductSingularName() {
        switch (this.product) {
            case Alarmanlage:
                return "Alarmanlage";

            case Videoueberwachung:
                return "Videoanlage";

            case Tuerschloss:
                return "Schließanlage";

            case Brand:
                return "Brandmeldeanlage";

            case Fieberkamera:
                return "Fieberkamera";

            case Einlasskontrolle:
                return "Einlasskontrollsystem";

            default:
                return "Sicherheitssystem";
        }
    }
}
